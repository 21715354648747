import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const SOILCARD_RESOURCE = "research-line";

const state = {
  researchLines: [],
  errors: null,
  cloneCard: false
};

const matrixLabels = {
	"serologia": "Serologia",
	"weterynaria": "Weterynaria",
	"pasza": "Pasza",
	"zywnosc": "Żywność",
	"woda": "Woda",
	"srodowisko": "Środowisko",
	"urzedowe": "Urzędowe"
};

const getters = {
  researchLinesRaw: state => state.researchLines,
	researchLines: ( state ) => {
		let ret = [];

		for (const [key, label] of Object.entries(matrixLabels)) 
		{
			ret.push({
				id: key,
				label,
				children: state
					.researchLines
					.filter( line => line.matrix == key )
					.map( line => {
						return {
							id: line.id,
							label: line.parameter + " " + line.method,
							deleted: line.deleted
						}
					})
			})
		}

		return ret;
	}
};

const actions = {
  researchLinesList(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(SOILCARD_RESOURCE )
        .then(({ data }) => {
          context.commit("setResearchLines", data.data);
        })
        .catch(({ response }) => {
          context.commit("setError", response);
        });
    } else {
      context.commit("purgeSoilCards");
    }
  },
  researchLinesAdd(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.post(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("researchLinesList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  researchLinesEdit(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.put(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("researchLinesList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  researchLinesDelete(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(SOILCARD_RESOURCE, id)
      .then(() => ctx.dispatch("researchLinesList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setResearchLines(state, data) {
    state.researchLines = data;
  },
  purgeResearchLines(state) {
    state.errors = null;
    state.researchLines = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};
