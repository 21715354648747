import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const USER_RESOURCE = "user";

// action types
export const USER_LIST = "userList";

// mutation types
export const SET_USERS = "setUsers";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: null,
  users: [],
};

const getters = {
  users(state) {
    return state.users;
  },
  user(state) {
    return state.user;
  },
};

const actions = {
  [USER_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(USER_RESOURCE)
        .then(({ data }) => {
          context.commit(SET_USERS, data.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    }
  },
  userAdd(ctx, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(USER_RESOURCE, data)
        .then(() => ctx.dispatch(USER_LIST))
        .catch(({ response }) => ctx.commit("setError", response));
    }
  },
  userEdit(ctx, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put(USER_RESOURCE, data)
        .then(() => ctx.dispatch(USER_LIST))
        .catch(({ response }) => ctx.commit("setError", response));
    }
  },
  userDelete(ctx, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.delete(USER_RESOURCE, data.id)
        .then(() => ctx.dispatch(USER_LIST))
        .catch(({ response }) => ctx.commit("setError", response));
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_USERS](state, users) {
    state.users = users;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
