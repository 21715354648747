import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const POSTALCODE_RESOURCE = "postalCode";

// action types
export const POSTALCODES_SEARCH = "searchPostalCodes";
export const CLEAR_POSTALCODES = "clearPostalCodes";

// mutation types
export const SET_POSTALCODES = "setPostalCodes";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  postalCodes: {
    address: [],
    deliveryAddress1: [],
    deliveryAddress2: [],
    fermAddress: []
  }

};

const getters = {
  postalCodes(state) {
    return state.postalCodes;
  }
};

const actions = {
  [POSTALCODES_SEARCH](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { postcode, section } = payload;
      ApiService.get(POSTALCODE_RESOURCE, postcode)
        .then(({ data }) => {
          context.commit(SET_POSTALCODES, {postalCodes: data.data, section});
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(CLEAR_POSTALCODES);
    }
  },
  [CLEAR_POSTALCODES](context, section) {
    context.commit(CLEAR_POSTALCODES, section);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_POSTALCODES](state, {postalCodes, section}) {
    state.postalCodes[section] = postalCodes;
  },
  [CLEAR_POSTALCODES](state, section) {
    state.postalCodes[section] = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
