import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {
  PURGE_CLIENTS,
} from "@/core/services/store/client.module";

// consts
export const FERM_RESOURCE = "ferm";
export const FERM_FOR_CLIENT_RESOURCE = "ferm/client";
export const FERM_SOFT_DELETE_RESOURCE = FERM_RESOURCE;
export const FERM_HARD_DELETE_RESOURCE = FERM_RESOURCE + "/" + "delete";

// action types
export const FERM_LIST = "fermsList";
export const FERM_FOR_CLIENT_LIST = "fermsForClientList";
export const FERM_DELETE = "fermsDelete";
export const FERM_SOFT_DELETE = "fermsSoftDelete";
export const FERM_HARD_DELETE = "fermsHardDelete";
export const FERM_ADD = "fermsAdd";
export const FERM_EDIT = "fermsEdit";
export const CLEAR_FERMS = "clearFerms";

// mutation types
export const SET_FERMS = "setFerms";
export const SET_ERROR = "setError";
export const PURGE_FERMS = "purgeFerms";

const state = {
  errors: null,
  ferms: []
};

const getters = {
  ferms(state) {
    return state.ferms;
  }
};

const actions = {
  [FERM_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(FERM_RESOURCE)
        .then(({ data }) => {
          context.commit(SET_FERMS, data.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_FERMS);
    }
  },
  [FERM_FOR_CLIENT_LIST](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.get(FERM_FOR_CLIENT_RESOURCE, id)
        .then(({ data }) => {
          context.commit(SET_FERMS, data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_FERMS);
    }
  },
  [FERM_SOFT_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(FERM_SOFT_DELETE_RESOURCE, id)
        .then(() => {
          let ferms = context.state.ferms;
          const index = ferms.findIndex(o => o.id === id);
          if (index !== -1) {
            ferms.splice(index, 1);
            context.commit(SET_FERMS, ferms);
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, "Error while deleting client");
        });
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  // [FERM_HARD_DELETE](context, payload) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     const { id } = payload;
  //     ApiService.delete(FERM_HARD_DELETE_RESOURCE, id)
  //       .then(({ data }) => {
  //         let ferms = context.state.ferms;
  //         // let newFerm = data.data.ferm;
  //         // let client = newFerm.client;
  //         const index = ferms.findIndex(n => n.id === id);
  //         if (index !== -1) {
  //           ferms.splice(index, 1);
  //           context.commit(SET_FERMS, ferms);
  //         }
  //         //console.log(client);
  //         // context.dispatch(CLIENT_EDIT_FIELD_OFFLINE, {
  //         //   name: "updatedAt",
  //         //   value: client.updatedAtObj,
  //         //   id: client.id
  //         // });
  //       })
  //       .catch(({ response }) => {
  //         context.commit(SET_ERROR, response);
  //       });
  //   } else {
  //     context.commit(PURGE_FERMS);
  //   }
  // },
  [FERM_ADD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      //console.log("ferm add");
      ApiService.post(FERM_RESOURCE, payload)
        .then(({ data }) => {
          let notes = context.state.ferms;
          let newFerm = data.data.ferm;
          // let client = newFerm.client;
          notes.push(newFerm);
          context.commit(SET_FERMS, notes);
          context.dispatch(FERM_LIST);
          // context.dispatch(CLIENT_EDIT_FIELD_OFFLINE, {
          //   name: "updatedAt",
          //   value: client.updatedAtObj,
          //   id: client.id
          // });
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_FERMS);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_FERMS](state, ferms) {
    state.ferms = ferms;
  },
  [PURGE_FERMS](state) {
    state.ferms = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [CLEAR_FERMS](state) {
    state.ferms = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
