import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";

export const VACATION_RESOURCE = "vacation-reservation";

const state = {
  error: null,
  vacationReservation: [],
  vacationReservationAll: []
};

const getters = {
  vacationReservation: state => state.vacationReservation,
  vacationReservationAll: state => state.vacationReservationAll
};

const actions = {
  vacationReservationList(context) {
    if (!JwtService.getToken()) context.commit("purgeVacationReservation");

    ApiService.setHeader();
    ApiService.get(VACATION_RESOURCE)
      .then(({ data }) => {
        context.commit("setVacationReservation", data.data);
      })
      .catch(({ response }) => {
        context.commit("setError", response);
      });
  },
  vacationReservationListAll(context) {
    if (!JwtService.getToken()) context.commit("purgeVacationReservation");

    ApiService.setHeader();
    ApiService.get(VACATION_RESOURCE + '-all')
      .then(({ data }) => {
        context.commit("setVacationReservationAll", data.data);
      })
      .catch(({ response }) => {
        context.commit("setError", response);
      });
  },
  vacationReservationAdd(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacationReservation");
    ApiService.setHeader();

    ApiService.post(VACATION_RESOURCE, data)
      .then(() => ctx.dispatch("vacationReservationList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  vacationReservationUpdate(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacationReservation");
    ApiService.setHeader();

    ApiService.put(VACATION_RESOURCE, data)
      .then(() => ctx.dispatch("vacationReservationList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  vacationReservationDelete(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacationReservation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(VACATION_RESOURCE, id)
      .then(() => ctx.dispatch("vacationReservationList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  }
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setVacationReservation(state, vacation) {
    state.vacationReservation = vacation;
  },
  setVacationReservationAll(state, v) {
    state.vacationReservationAll = v;
  },
  purgeVacationReservation(state) {
    state.vacation = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  clearVacationReservation(state) {
    state.vacationReservation = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
