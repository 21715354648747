import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const CLIENT_RESOURCE = "client";
export const CLIENT_SOFT_DELETE_RESOURCE = CLIENT_RESOURCE;
export const CLIENT_HARD_DELETE_RESOURCE = CLIENT_RESOURCE + "/" + "delete";
export const CLIENT_RESOURCE_FIELD = "clientField";

// action types
export const CLIENT_LIST = "clientList";
export const CLIENT_SEARCH = "clientSearch";
export const CLEAR_FOUND_CLIENT = "clearFoundClient";
export const CLIENT_DELETE = "clientDelete";
export const CLIENT_SOFT_DELETE = "clientSoftDelete";
export const CLIENT_HARD_DELETE = "clientHardDelete";
export const CLIENT_ADD = "clientAdd";
export const CLIENT_EDIT = "clientEdit";
export const CLIENT_EDIT_FIELD = "clientEditField";
export const CLIENT_EDIT_FIELD_OFFLINE = "clientEditFieldOffline";

// mutation types
export const SET_CLIENTS = "setClients";
export const SET_ERROR = "setError";
export const PURGE_CLIENTS = "purgeClients";
export const SET_FOUND_CLIENT = "setFoundClient";
export const PURGE_FOUND_CLIENT = "purgeFoundClient";
export const SET_SEARCHING_STATUS = "setSearchingStatus";
export const SET_INLINE_EDITING_STATUS_ID = "setInlineEditingStatusId";

const state = {
  errors: null,
  client: null,
  clients: [],
  foundClient: null,
  searchingStatus: false,
  inlineEditingStatusId: -1
};

const getters = {
  clientErrors(state) {
    return state.errors;
  },
  clients(state) {
    return state.clients;
  },
  client(state) {
    return state.client;
  },
  searchingStatus() {
    return state.searchingStatus;
  },
  foundClient() {
    return state.foundClient;
  },
  inlineEditingStatusId() {
    return state.inlineEditingStatusId;
  }
};

const actions = {
  [SET_ERROR](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      context.commit(SET_ERROR, payload);
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  [CLIENT_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(CLIENT_RESOURCE)
        .then(({ data }) => {
          context.commit(SET_CLIENTS, data.data);
          return data;
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response.data.statusMsg); //changed
        });
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  [CLIENT_SEARCH](context, payload) {
    context.commit(SET_SEARCHING_STATUS, false);

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("ws/ceidg/client", payload)
        .then(({ data }) => {
          context.commit(SET_FOUND_CLIENT, data.data);
          context.commit(SET_SEARCHING_STATUS, true);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response.data.statusMsg); //changed
          context.commit(PURGE_FOUND_CLIENT);
          context.commit(SET_SEARCHING_STATUS, false);
        });
    } else {
      context.commit(PURGE_FOUND_CLIENT);
      context.commit(SET_SEARCHING_STATUS, false);
    }
  },
  [CLEAR_FOUND_CLIENT](context) {
    context.commit(PURGE_FOUND_CLIENT);
    context.commit(SET_SEARCHING_STATUS, false);
  },
  [CLIENT_ADD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(CLIENT_RESOURCE, payload)
        .then(({ data }) => {
          let clients = context.state.clients;
          let newPayload = data.data.client;
          newPayload.createdAt = newPayload.createdAtObj;
          newPayload.updatedAt = newPayload.updatedAtObj;
          clients.push(newPayload);
          context.commit(SET_CLIENTS, clients);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response.data.statusMsg); //changed
        });
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  [CLIENT_EDIT](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put(CLIENT_RESOURCE, payload)
        .then(({ data }) => {
          let clients = context.state.clients;
          let newPayload = data.data.client;
          newPayload.createdAt = newPayload.createdAtObj;
          newPayload.updatedAt = newPayload.updatedAtObj;
          const index = clients.findIndex(o => o.id === newPayload.id);
          if (index !== -1) {
            clients.splice(index, 1, newPayload);
            context.commit(SET_CLIENTS, clients);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response.data.statusMsg); //changed
        });
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  [CLIENT_EDIT_FIELD](context, payload) {
    if (JwtService.getToken()) {
      context.commit(SET_INLINE_EDITING_STATUS_ID, payload.id);
      ApiService.setHeader();
      ApiService.put(CLIENT_RESOURCE_FIELD, payload)
        .then(({ data }) => {
          let clients = context.state.clients;
          let newPayload = data.data.client;
          const index = clients.findIndex(o => o.id === newPayload.id);
          if (index !== -1) {
            clients.splice(index, 1, newPayload);
            context.commit(SET_CLIENTS, clients);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response.data.statusMsg); //changed
        })
        .finally(() => {
          context.commit(SET_INLINE_EDITING_STATUS_ID, -1);
        });
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  [CLIENT_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(CLIENT_RESOURCE, id)
        .then(() => {
          let clients = context.state.clients;
          const index = clients.findIndex(o => o.id === id);
          if (index !== -1) {
            clients.splice(index, 1);
            context.commit(SET_CLIENTS, clients);
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, "Error while deleting client");
        });
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  [CLIENT_SOFT_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(CLIENT_SOFT_DELETE_RESOURCE, id)
        .then(() => {
          let clients = context.state.clients;
          const index = clients.findIndex(o => o.id === id);
          if (index !== -1) {
            clients.splice(index, 1);
            context.commit(SET_CLIENTS, clients);
          }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, "Error while deleting client");
        });
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  [CLIENT_HARD_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(CLIENT_HARD_DELETE_RESOURCE, id)
        .then(() => {
          let clients = context.state.clients;
          const index = clients.findIndex(o => o.id === id);
          if (index !== -1) {
            clients.splice(index, 1);
            context.commit(SET_CLIENTS, clients);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response.data.statusMsg); //changed
        });
    } else {
      context.commit(PURGE_CLIENTS);
    }
  },
  [CLIENT_EDIT_FIELD_OFFLINE](context, payload) {
    if (JwtService.getToken()) {
      let clients = context.state.clients;
      let newPayload = { ...payload };

      const index = clients.findIndex(o => o.id === newPayload.id);
      if (index !== -1) {
        clients[index][newPayload.name] = newPayload.value;
        context.commit(SET_CLIENTS, clients);
      }
    } else {
      context.commit(PURGE_CLIENTS);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CLIENTS](state, clients) {
    state.clients = clients;
  },
  [PURGE_CLIENTS](state) {
    state.clients = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_FOUND_CLIENT](state, foundClient) {
    state.foundClient = foundClient;
  },
  [PURGE_FOUND_CLIENT](state) {
    state.foundClient = null;
  },
  [SET_SEARCHING_STATUS](state, status) {
    state.searchingStatus = status;
  },
  [SET_INLINE_EDITING_STATUS_ID](state, status) {
    state.inlineEditingStatusId = status;
  },
  clearClients(state) {
    state.clients = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
