import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";

export const VACATION_RESOURCE = "vacation";

const state = {
  error: null,
  vacation: []
};

const getters = {
  vacation: state => state.vacation
};

const actions = {
  vacationList(context) {
    if (!JwtService.getToken()) context.commit("purgeVacation");

    ApiService.setHeader();
    ApiService.get(VACATION_RESOURCE)
      .then(({ data }) => {
        context.commit("setVacation", data.data);
      })
      .catch(({ response }) => {
        context.commit("setError", response);
      });
  },
  vacationAdd(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.post(VACATION_RESOURCE, data)
      .then(() => ctx.dispatch("vacationList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  vacationUpdate(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.put(VACATION_RESOURCE, data)
      .then(() => ctx.dispatch("vacationList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  vacationDelete(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(VACATION_RESOURCE, id)
      .then(() => ctx.dispatch("vacationList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  }
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setVacation(state, vacation) {
    state.vacation = vacation;
  },
  purgeVacation(state) {
    state.vacation = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  clearVacation(state) {
    state.vacation = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
