import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const _RESOURCE = "lab-chart";

const state = {
  errors: null,
  charts: [],
  chart: null
};

const getters = {
  charts: (state) => state.charts,
  currentChart: (state) => state.chart
};

const actions = {
  getLabCharts(ctx) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(_RESOURCE)
        .then(({ data }) => {
          ctx.commit("setLabCharts", data.data);
        })
        .catch(({ response }) => {
          ctx.commit("setError", response);
        });
    } else {
      ctx.commit("purgeLabControl");
    }
  },
  getLabChart(ctx, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get (_RESOURCE + "/" + data.id )
        .then(({ data  }) => {
          ctx.commit("setLabChart", data.data);
        })
        .catch(({ response }) => {
          ctx.commit("setError", response);
        });
    } else {
      ctx.commit("purgeLabControl");
    }
  },
  storeLabChart(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.post(_RESOURCE, data)
      .then(() => ctx.dispatch("getLabCharts"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  deleteLabChart(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(_RESOURCE, id)
      .then(() => ctx.dispatch("getLabCharts"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  }
};

const mutations = {
  setLabCharts(state, data) {
    state.charts = data;
  },
  clearLabCharts(state) {
    state.charts = [];
  },
  setLabChart(state, data) {
    state.chart = data;
  },
  clearLabChart(state) {
    state.chart = null;
  },
  setErrors(state, data) {
    state.errors = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
