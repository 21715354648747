import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const PRODUCT_RESOURCE = "product";
export const PRODUCT_SOFT_DELETE_RESOURCE = PRODUCT_RESOURCE;
export const PRODUCT_HARD_DELETE_RESOURCE = PRODUCT_RESOURCE + "/" + "delete";

// action types
export const PRODUCT_LIST = "productList";
export const PRODUCT_ALL_LIST = "productAllList";
export const PRODUCT_DELETE = "productDelete";
export const PRODUCT_SOFT_DELETE = "productSoftDelete";
export const PRODUCT_HARD_DELETE = "productHardDelete";
export const PRODUCT_ADD = "productAdd";
export const PRODUCT_EDIT = "productEdit";
export const CLEAR_PRODUCTS = "clearProducts";

// mutation types
export const SET_PRODUCTS = "setProducts";
export const SET_PRODUCTS_ALL = "setProductsAll";
export const SET_ERROR = "setError";
export const PURGE_PRODUCTS = "purgeProducts";

const state = {
  errors: null,
  products: [],
  productsAll: []
};

const getters = {
  products(state) {
    return state.products;
  },
  productsAll(state) {
    return state.productsAll;
  }
};

const actions = {
  [PRODUCT_ALL_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const queryParams = {
        all: 1
      };
      ApiService.get(PRODUCT_RESOURCE, "", queryParams)
        .then(({ data }) => {
          context.commit(SET_PRODUCTS_ALL, data.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_PRODUCTS);
    }
  },
  [PRODUCT_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(PRODUCT_RESOURCE)
        .then(({ data }) => {
          context.commit(SET_PRODUCTS, data.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_PRODUCTS);
    }
  },
  [PRODUCT_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(PRODUCT_RESOURCE, id)
        .then(() => {
          let products = context.state.products;
          const index = products.findIndex(o => o.id === id);
          if (index !== -1) {
            products.splice(index, 1);
            context.commit(SET_PRODUCTS, products);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_PRODUCTS);
    }
  },
  [PRODUCT_SOFT_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(PRODUCT_SOFT_DELETE_RESOURCE, id)
        .then(() => {
          let products = context.state.products;
          const index = products.findIndex(o => o.id === id);
          if (index !== -1) {
            products.splice(index, 1);
            context.commit(SET_PRODUCTS, products);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_PRODUCTS);
    }
  },
  [PRODUCT_HARD_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(PRODUCT_HARD_DELETE_RESOURCE, id)
        .then(() => {
          let products = context.state.products;
          const index = products.findIndex(o => o.id === id);
          if (index !== -1) {
            products.splice(index, 1);
            context.commit(SET_PRODUCTS, products);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_PRODUCTS);
    }
  },
  [PRODUCT_ADD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(PRODUCT_RESOURCE, payload)
        .then(({ data }) => {
          let products = context.state.products;
          let newPayload = { ...payload };
          newPayload.id = data.data.id;
          newPayload.imagePath = data.data.imagePath;
          products.push(newPayload);
          context.commit(SET_PRODUCTS, products);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_PRODUCTS);
    }
  },
  [PRODUCT_EDIT](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put(PRODUCT_RESOURCE, payload)
        .then(({ data }) => {
          let products = context.state.products;
          let newPayload = { ...payload };
          const index = products.findIndex(o => o.id === newPayload.id);
          newPayload.imagePath = data.data.imagePath;
          if (index !== -1) {
            products.splice(index, 1, newPayload);
            context.commit(SET_PRODUCTS, products);
          }
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_PRODUCTS);
    }
  },
  [CLEAR_PRODUCTS](context) {
    context.commit(CLEAR_PRODUCTS);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_PRODUCTS](state, products) {
    state.products = products;
  },
  [SET_PRODUCTS_ALL](state, products) {
    state.productsAll = products;
  },
  [PURGE_PRODUCTS](state) {
    state.products = [];
    state.productsAll = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [CLEAR_PRODUCTS](state) {
    state.products = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
