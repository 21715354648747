import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const SOILCARD_RESOURCE = "soil-card";

const state = {
  soilCards: [],
  errors: null,
  filters: {
    pm: "",
    name: "",
    shortcode: "",
    type: "",
    datePrepared: "",
    status: "",
  },
  cloneCard: false
};

const getCardParamValue = (card, param) => {
  let paramValue = null;

  try {
    if (param === "preparationDate") {
      if (card.type === "ready")
        paramValue =
          card.meta_data.general[
            card.meta_data.general.findIndex(
              (el) => el.key === "datePackageAdmitted"
            )
          ].value.trim();
      else
        paramValue =
          card.meta_data.prep.rows[
            card.meta_data.prep.rows.findIndex(
              (el) => el.key === ( card.type === 'test' ? "soilPrepedDate" : "soilPreparationDate" )
            )
          ].value.trim();
    } else if (
      card.type !== 'ready'
      && param === 'soilDryExpiry'
    ) {
      paramValue =
          card.meta_data.prep.rows[
            card.meta_data.prep.rows.findIndex(
              (el) => el.key === ( card.type === 'test' ? "prepedSoilExpiryDate" : "soilExpiryDate" )
            )
          ].value.trim();
    } else if (
      card.type === "test" &&
      ["soilName", "soilShortcut"].includes(param)
    ) {
      paramValue =
        card.meta_data.prep.rows[
          card.meta_data.prep.rows.findIndex((el) => el.key === param)
        ].value.trim();
    } else {
      if (card.type === "ready")
        paramValue =
          card.meta_data.general[
            card.meta_data.general.findIndex((el) => el.key === param)
          ].value.trim();
      else
        paramValue =
          card.meta_data.acceptance.rows[
            card.meta_data.acceptance.rows.findIndex((el) => el.key === param)
          ].value.trim();
    }
  } catch (e) {
    if (card.type === "type") {
      // console.error(e);
    }
    return "";
  }

  return paramValue;
};

const getSecondLevelCards = (cards, mark) => {
  return cards.filter((el) => {
    return (
      getCardParamValue(el, "soilMark") == mark &&
      el.level == 2
    );
  });
};

const getThirdLevelCards = (cards, mark, soilSerialNo) => {
  return cards.filter((el) => {
    return (
      getCardParamValue(el, "soilMark") == mark &&
      getCardParamValue(el, "soilSerialNo") == soilSerialNo &&
      el.level === 3
    );
  });
};

const getters = {
  cloneCard: (state) => state.cloneCard,
  soilCards: (state) => {
    let cards = [];

    for ( const card of state.soilCards ) {
      cards.push(card);

      if ( card.children.length > 0 )
      {
        for ( const lv2 of card.children )
        {
          cards.push(lv2);

          if ( lv2.children.length > 0 ) 
          {
            for ( const lv3 of lv2.children )
              cards.push(lv3)
          }
        }
      }
    }

    
    return cards.filter(el => el.status != 'finished' );
  },
  soilCardsAll: (state) => {
    let cards = [];

    for ( const card of state.soilCards ) {
      cards.push(card);

      if ( card.children.length > 0 )
      {
        for ( const lv2 of card.children )
        {
          cards.push(lv2);

          if ( lv2.children.length > 0 ) 
          {
            for ( const lv3 of lv2.children )
              cards.push(lv3)
          }
        }
      }
    }
    return cards;
  },
 
  // Get the first tier of soil cards
  soilCardsListing: (state) =>
    state.soilCards.filter((el) => el.level === 1),
};

const actions = {
  soilCardsList(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(SOILCARD_RESOURCE + "/list")
        .then(({ data }) => {
          context.commit("setSoilCards", data.data);
        })
        .catch(({ response }) => {
          context.commit("setError", response);
        });
    } else {
      context.commit("purgeSoilCards");
    }
  },
  soilCardAdd(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.post(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("soilCardsList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  soilCardEdit(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.put(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("soilCardsList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  soilCardUpdateStatus(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.put(SOILCARD_RESOURCE + "/status", data)
      .then(() => ctx.dispatch("soilCardsList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  soilCardDelete(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(SOILCARD_RESOURCE, id)
      .then(() => ctx.dispatch("soilCardsList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  soilCardPrint(ctx, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = data;
      ApiService.download('soil-card/' + id + "/print")
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", data.file_name + ".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(({ response }) => {
          ctx.commit("setError", response);
        })
    }
  }
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setSoilCards(state, data) {
    state.soilCards = data;
  },
  purgeSoilCards(state) {
    state.errors = null;
    state.soilCards = [];
  },
  setFilters(state, filters) {
    state.filters = filters;
  },
  setCloneCard(state, card) {
    state.cloneCard = card;
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};

export { getSecondLevelCards, getThirdLevelCards, getCardParamValue };
