import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const SOILCARD_RESOURCE = "drug-card";

const state = {
  drugCards: [],
  errors: null
};

const getters = {
  drugCards: ( state ) => state.drugCards
};

const actions = {
  drugCardsList(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(SOILCARD_RESOURCE)
        .then(({ data }) => {
          context.commit("setDrugCards", data.data);
        })
        .catch(({ response }) => {
          context.commit("setError", response);
        });
    } else {
      context.commit("purgeDrugCards");
    }
  },
  drugCardAdd(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.post(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("drugCardsList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  drugCardEdit(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.put(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("drugCardsList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  drugCardDelete(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(SOILCARD_RESOURCE, id)
      .then(() => ctx.dispatch("drugCardList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setDrugCards(state, data) {
    state.drugCards = data;
  },
  purgeDrugCards(state) {
    state.errors = null;
    state.drugCards = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};