import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import product from "./product.module";
import notice from "./notice.module.js";
import ferm from "./ferm.module";
import order from "./order.module";
import client from "./client.module";
import user from "./user.module";
import postalCode from "./postalCode.module";
import variable from "./variable.module";
import vacation from "./vacation.module";
import analysis from "./analysis.module";
import soil from "./soil.module";
import labControl from "./labControl.module";
import drugCard from "./drugCard";
import suplement from "./suplement.module"
import orderLab from "./orderLab.module";
import researchLine from "./researchLine.module";
import labChart from "./labChart.module";
import vacationReservation from "./vacationReservation.module";
import labResult from "./labResult.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    product,
    notice,
    ferm,
    order,
    client,
    user,
    postalCode,
    variable,
    vacation,
    analysis,
    soil,
    labControl,
    drugCard,
    suplement,
    orderLab,
    researchLine,
    labChart,
    vacationReservation,
    labResult
  }
});
