import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const SOILCARD_RESOURCE = "lab-result";

const state = {
    labResult: null,
    labResults: [],
    waitingResults: null
};

const getters = {
    labResult: (state) => state.labResult,
    waitingResults: (state) => state.waitingResults,
    labResults: (state) => state.labResults.map( result => {
        return {
            id: result.id,
            number: result.number,
            double: result.result_data.doubleResult == 'true' ? "Tak" : "Nie",
            group: result.result_data.groupResult == 'true' ? "Tak" : "Nie",
            date_created: result.date_created.date,  
            order_id: result.lab_order.id
        }
    })
}

const actions = {
    labResultAdd(ctx, data) {
        if (!JwtService.getToken()) ctx.commit("purgeVacation");
        ApiService.setHeader();

        ApiService.post(SOILCARD_RESOURCE, data.data)
        .then(() => ctx.dispatch("orderLabList"))
        .catch(({ response }) => {
            ctx.commit("setError", response);
        });
    },
    labResultEdit(ctx, data) {
        if (!JwtService.getToken()) ctx.commit("purgeVacation");
        ApiService.setHeader();

        ApiService.put(SOILCARD_RESOURCE, data)
        .then(() => ctx.dispatch("orderLabList"))
        .catch(({ response }) => {
            // console.error(response)
            ctx.commit("setError", response);
        });
    },
    labResultGet(ctx, data) {
        if (!JwtService.getToken()) ctx.commit("purgeVacation");
        ApiService.setHeader();

        return ApiService.get(SOILCARD_RESOURCE + "/order/" + data.labResultId)
        .then((data) => ctx.commit("setLabResult", data.data.data))
        .catch(({ response }) => {
            // console.error(response)
            ctx.commit("setError", response);
        });
    },
    getWaitingResults(ctx) {
        if (!JwtService.getToken()) ctx.commit("purgeVacation");
        ApiService.setHeader();

        ApiService.get(SOILCARD_RESOURCE + "/waiting")
        .then((data) => ctx.commit("setWaitingResults", data.data.data))
        .catch(({ response }) => {
            // console.error(response)
            ctx.commit("setError", response);
        });
    },
    labResultAccept(ctx, data) {
        if (!JwtService.getToken()) ctx.commit("purgeVacation");
        ApiService.setHeader();

        ApiService.put(SOILCARD_RESOURCE + "/accept/" + data.id)
            .then(() => ctx.dispatch("getWaitingResults"))
            .catch(({ response }) => {
                // console.error(response)
                ctx.commit("setError", response);
            });
    },
    getOrderResults(ctx, data) {
        if (!JwtService.getToken()) ctx.commit("purgeVacation");
        ApiService.setHeader();

        ApiService.get(SOILCARD_RESOURCE + "/order-list/" + data.id)
        .then((data) => ctx.commit("addLabResultsForOrder", data.data.data))
        .catch(({ response }) => {
            // console.error(response)
            ctx.commit("setError", response);
        });
    },
    resultPrint(ctx, data) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            const { id } = data;
            ApiService.download('lab-result/print', id)
              .then(response => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                const fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "raport-badania.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
              })
              .catch(({ response }) => {
                ctx.commit("setError", response);
              })
          }
    },
    labResultDelete(ctx, data) {
        if (!JwtService.getToken()) ctx.commit("purgeVacation");
        ApiService.setHeader();

        const { id } = data;
        ApiService.delete('lab-result', id)
        .then()
        .catch(({ response }) => {
            ctx.commit("setError", response);
        });
    },
    labResultSendEmail(ctx, data) {
        if (!JwtService.getToken()) ctx.commit("purgeVacation");
        ApiService.setHeader();

        const { id } = data;
        ApiService.get('lab-result/' + id +  '/send')
        .then( resp => console.log(resp) )
        .catch(({ response }) => {
            ctx.commit("setError", response);
        });
    }
}

const mutations = {
    setLabResult(state, data) {
        state.labResult = data;
    },
    purgeLabResult(state) {
        console.log('purging')
        state.labResult = null;
    },
    setWaitingResults(state, data) {
        state.waitingResults = data;
    },
    removeFromListForOrder(state, orderId) {
        state.labResults = state.labResults.filter( el => el.lab_order.id != orderId )
    },
    addLabResultsForOrder(state, data) {
        let ids = data.map( e => e.id );

        state.labResults = [
            ...state.labResults.filter( e => !ids.includes(e.id)),
            ...data
        ]
    }
}

export default {
    state, getters, actions, mutations
};