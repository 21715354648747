import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/customer",
          name: "customer",
          component: () => import("@/view/pages/customer/Customer.vue")
        },
        {
          path: "/product",
          name: "product",
          component: () => import("@/view/pages/product/Product.vue")
        },
        {
          path: "/orders",
          name: "orders",
          component: () => import("@/view/pages/orders/Orders.vue")
        },
        {
          path: "/orderPanel",
          name: "orderPanel",
          component: () => import("@/view/pages/orderPanel/OrderPanel.vue")
        },
        {
          path: "/orderPanelLab",
          name: "orderPanelLab",
          component: () =>
            import("@/view/pages/orderPanelLab/OrderPanelLab.vue")
        },
        {
          path: "/orderPanelLab/new/:clientId",
          name: "orderLabNew",
          component: () =>
            import("@/view/pages/orderLabNew/OrderLabNew.vue"),
          props: true
        },
        {
          path: "/orderPanelLab/edit/:orderId/:clientId",
          name: "orderLabNew",
          component: () =>
            import("@/view/pages/orderLabNew/OrderLabNew.vue"),
          props: true
        },
        {
          path: "/soilCards",
          name: "soilCards",
          component: () => import('@/view/pages/orderPanelLab/SoilCards/SoilCardsIndex'),
          props: true
        },
        {
          path: "/soilCards/:cardId/edit",
          name: "soilCardEdit",
          component: () => import('@/view/pages/orderPanelLab/SoilCards/SoilCardsAdd'),
          props: true
        },
        {
          path: "/soilCards/add",
          name: "soilCardsAdd",
          component: () => import('@/view/pages/orderPanelLab/SoilCards/SoilCardsAdd'),
          props: true
        },
        {
          path: "/myCompany",
          name: "myCompany",
          component: () => import("@/view/pages/myCompany/Vacation.vue"),
          props: true
        },
        {
          path: "/myCompany/analysis",
          name: "business-analysis",
          component: () => import("@/view/pages/myCompany/Analysis"),
          props: true
        },
        {
          path: "/myCompany/users",
          name: "users",
          component: () => import("@/view/pages/myCompany/Users"),
          props: true
        },
        {
          path: "/myCompany/users/add",
          name: "usersAdd",
          component: () => import("@/view/pages/myCompany/UserAdd"),
          props: true
        },
        {
          path: "/myCompany/users/:userId/edit",
          name: "usersEdit",
          component: () => import("@/view/pages/myCompany/UserAdd"),
          props: true
        },
        {
          path: "/lab/control",
          name: "labControll",
          component: () => import("@/view/pages/lab/control/LabControl"),
          props: true
        },
        {
          path: "/lab/control/add",
          name: "labControllAdd",
          component: () => import("@/view/pages/lab/control/LabControlForm"),
          props: true
        },
        {
          path: "/lab/control/:id/edit",
          name: "labControllEdit",
          component: () => import("@/view/pages/lab/control/LabControlForm"),
          props: true
        },
        {
          path: "/lab/drug-card",
          name: "labDrugCard",
          component: () => import("@/view/pages/lab/drugCard/DrugCardIndex.vue"),
          props: true
        },
        {
          path: "/lab/drug-card/add",
          name: "labDrugCardAdd",
          component: () => import("@/view/pages/lab/drugCard/DrugCardForm.vue"),
          props: true
        },
        {
          path: "/lab/drug-card/:id/edit",
          name: "labDrugCardEdit",
          component: () => import("@/view/pages/lab/drugCard/DrugCardForm.vue"),
          props: true
        },
        {
          path: "/lab/suplement",
          name: "labSuplement",
          component: () => import("@/view/pages/lab/suplement/suplementIndex.vue"),
          props: true
        },
        {
          path: "/lab/suplement/add",
          name: "labSuplementAdd",
          component: () => import("@/view/pages/lab/suplement/suplementForm.vue"),
          props: true
        },
        {
          path: "/lab/suplement/:id/edit",
          name: "labSuplementEdit",
          component: () => import("@/view/pages/lab/suplement/suplementForm.vue"),
          props: true
        },
        {
          path: "/labl/labOrders",
          name: "labOrdersListing",
          component: () => import("@/view/pages/orderPanelLab/LabOrderList"),
          props: true
        },
        {
          path: "/lab/researchLine/edit/:id",
          name: "labResearchEdit",
          component: () => import("@/view/pages/orderPanelLab/researchLines/ResearchLinesForm"),
          props: true
        },
        {
          path: "/lab/researchLine/add/",
          name: "labResearchAdd",
          component: () => import("@/view/pages/orderPanelLab/researchLines/ResearchLinesForm"),
          props: true
        },
        {
          path: "/lab/researchLine",
          name: "labResearchList",
          component: () => import("@/view/pages/orderPanelLab/researchLines/ResearchLinesList"),
          props: true
        },
        {
          path: "/lab/order/:orderId/chart", // TODO: Change this later
          name: "labChartForm",
          component: () => import("@/view/pages/lab/chart/LabChartForm"),
          props: true
        },
        {
          path: "/lab/order/:orderId/chart/:chartId", // TODO: Change this later
          name: "labChartFormEdit",
          component: () => import("@/view/pages/lab/chart/LabChartForm"),
          props: true
        },
        {
          path: "/lab/order/:orderId/result",
          name: "labResultForm",
          component: () => import("@/view/pages/lab/result/ResultForm"),
          props: true
        },
        {
          path: "/lab/orderResult/:resultId/edit/:orderId",
          name: "labResultForm",
          component: () => import("@/view/pages/lab/result/ResultForm"),
          props: true
        },
        {
          path: "/lab/results/waiting",
          name: "labWaitingResults",
          component: () => import("@/view/pages/lab/result/WaitingList"),
          props: true
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-2"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-2")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-2")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
