import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import jwt from "jsonwebtoken";
import { publicKey } from "../../../../publicKey.js";
import { SET_PERSONAL_INFO } from "../store/profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.token);
          context.commit(SET_PERSONAL_INFO, {
            name: state.user.firstName,
            surname: state.user.lastName,
            email: state.user.email
          });
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.message);
          reject();
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.token);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject();
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        jwt.verify(JwtService.getToken(), publicKey, (err, decoded) => {
          if (err) {
            context.commit(PURGE_AUTH);
            reject();
          } else {
            context.commit(SET_PERSONAL_INFO, {
              name: decoded.firstName,
              surname: decoded.lastName,
              email: decoded.email,
              roles: decoded.roles
            });
            resolve();
          }
        });
      });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, token) {
    try {
      state.user = jwt.verify(token, publicKey);
      JwtService.saveToken(token);
      state.isAuthenticated = true;
      state.errors = {};
    } catch (err) {
      state.errors = err;
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
