import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const SOILCARD_RESOURCE = "lab-controll";

const state = {
  labControl: [],
  errors: null
};

const getters = {
  labControl: ( state ) => state.labControl
};

const actions = {
  labControlList(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(SOILCARD_RESOURCE)
        .then(({ data }) => {
          context.commit("setLabControl", data.data);
        })
        .catch(({ response }) => {
          context.commit("setError", response);
        });
    } else {
      context.commit("purgeLabControl");
    }
  },
  labControlAdd(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.post(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("labControlList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  labControlEdit(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.put(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("labControlList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  labControlDelete(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(SOILCARD_RESOURCE, id)
      .then(() => ctx.dispatch("labControlList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  }
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setLabControl(state, data) {
    state.labControl = data;
  },
  purgeLabControl(state) {
    state.errors = null;
    state.labControl = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters,
};