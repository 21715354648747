import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const VARIABLE_RESOURCE = "variable";

// action types
export const VARIABLE_LIST = "variableList";
export const CLEAR_ORDERS = "clearOrders";

// mutation types
export const SET_VARIABLES = "setVariables";
export const SET_ERROR = "setError";
export const PURGE_VARIABLES = "purgeVariables";

const state = {
  errors: null,
  variables: []
};

const getters = {
  variables(state) {
    return state.variables;
  }
};

const actions = {
  [VARIABLE_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(VARIABLE_RESOURCE)
        .then(({ data }) => {
          context.commit(SET_VARIABLES, data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_VARIABLES);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_VARIABLES](state, orders) {
    state.variables = orders;
  },
  [PURGE_VARIABLES](state) {
    state.variables = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [CLEAR_ORDERS](state) {
    state.variables = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
