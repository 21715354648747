import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const SOILCARD_RESOURCE = "suplement";

const state = {
  suplements: [],
  errors: null
};

const getters = {
  suplements: ( state ) => state.suplements,
  suplemenstByName: ( state, name ) => state.suplements.filter( el => el.name == name )
};

const actions = {
  suplementList(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(SOILCARD_RESOURCE)
        .then(({ data }) => {
          context.commit("setSuplements", data.data);
        })
        .catch(({ response }) => {
          context.commit("setError", response);
        });
    } else {
      context.commit("purgeSuplement");
    }
  },
  suplementAdd(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.post(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("suplementList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  suplementEdit(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.put(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("suplementList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  suplementDelete(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(SOILCARD_RESOURCE, id)
      .then(() => ctx.dispatch("suplementList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  }
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setSuplements(state, data) {
    state.suplements = data;
  },
  purgeSuplements(state) {
    state.errors = null;
    state.suplements = [];
  }
};

export default {
  state, actions, mutations, getters
};