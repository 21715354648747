import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// consts
export const ORDER_RESOURCE = "order";
export const ORDER_SOFT_DELETE_RESOURCE = ORDER_RESOURCE;
export const ORDER_HARD_DELETE_RESOURCE = ORDER_RESOURCE + "/" + "delete";
export const ORDER_PRINT_RESOURCE = ORDER_RESOURCE + "/" + "print";
export const ORDER_PRINT_DEMAND_RESOURCE = ORDER_RESOURCE + "/" + "print-demand";
export const ORDER_SEND_MAIL_RESOURCE = ORDER_RESOURCE + "/" + "mail";
export const ORDER_RESOURCE_FIELD = "soField";
export const ORDER_RESOURCE_FILE = "soFile";

// action types
export const ORDER_LIST = "orderList";
export const ORDER_DELETE = "orderDelete";
export const ORDER_SOFT_DELETE = "orderSoftDelete";
export const ORDER_HARD_DELETE = "orderHardDelete";
export const ORDER_ADD = "orderAdd";
export const ORDER_EDIT = "orderEdit";
export const ORDER_EDIT_FIELD = "orderEditField";
export const ORDER_EDIT_FILE = "orderEditFile";
export const ORDER_PRINT = "orderPrint";
export const ORDER_SEND_MAIL = "orderSendMail";
export const CLEAR_ORDERS = "clearOrders";

// mutation types
export const SET_ORDERS = "setOrders";
export const SET_ERROR = "setError";
export const PURGE_ORDERS = "purgeOrders";
export const SET_IS_PRINTING = "setIsPrinting";

const state = {
  errors: null,
  orders: [],
  isPrinting: false
};

const getters = {
  orders(state) {
    return state.orders;
  },
  isPrinting(state) {
    return state.isPrinting;
  }
};

const actions = {
  [ORDER_SEND_MAIL](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(ORDER_SEND_MAIL_RESOURCE, payload)
        .then(() => {
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    }
  },
  [ORDER_PRINT](context, payload) {
    if (JwtService.getToken()) {
      context.commit(SET_IS_PRINTING, true);
      ApiService.setHeader();
      const { id } = payload;
      ApiService.download(ORDER_PRINT_RESOURCE, id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "order_" + id + ".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        })
        .finally(() => {
          context.commit(SET_IS_PRINTING, false);
        });
    }
  },
  orderPrintDemandOfPayment(context, payload) {
    if (JwtService.getToken()) {
      context.commit(SET_IS_PRINTING, true);
      ApiService.setHeader();
      const { id } = payload;
      ApiService.download(ORDER_PRINT_DEMAND_RESOURCE, id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "order_" + id + ".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        })
        .finally(() => {
          context.commit(SET_IS_PRINTING, false);
        });
    }
  },
  [ORDER_LIST](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(ORDER_RESOURCE)
        .then(({ data }) => {
          context.commit(SET_ORDERS, data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_ORDERS);
    }
  },
  [ORDER_ADD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(ORDER_RESOURCE, payload)
        .then(({ data }) => {
          let orders = context.state.orders;
          let newPayload = { ...payload };

          newPayload.id = data.data.id;
          newPayload.filePath = data.data.filePath;
          orders.push(newPayload);
          context.commit(SET_ORDERS, orders);
          context.dispatch(ORDER_LIST);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_ORDERS);
    }
  },
  [ORDER_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(ORDER_RESOURCE, id)
        .then(() => {
          let orders = context.state.orders;
          const index = orders.findIndex(o => o.id === id);
          if (index !== -1) {
            orders.splice(index, 1);
            context.commit(SET_ORDERS, orders);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_ORDERS);
    }
  },
  [ORDER_SOFT_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(ORDER_SOFT_DELETE_RESOURCE, id)
        .then(() => {
          let orders = context.state.orders;
          const index = orders.findIndex(o => o.id === id);
          if (index !== -1) {
            orders.splice(index, 1);
            context.commit(SET_ORDERS, orders);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_ORDERS);
    }
  },
  [ORDER_HARD_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(ORDER_HARD_DELETE_RESOURCE, id)
        .then(() => {
          let orders = context.state.orders;
          const index = orders.findIndex(o => o.id === id);
          if (index !== -1) {
            orders.splice(index, 1);
            context.commit(SET_ORDERS, orders);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_ORDERS);
    }
  },
  [ORDER_EDIT](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put(ORDER_RESOURCE, payload)
        .then(({ data }) => {
          let orders = context.state.orders;
          let newPayload = { ...payload };

          const index = orders.findIndex(o => o.id === data.id);
          if (index !== -1) {
            orders.splice(index, 1, newPayload);
            context.commit(SET_ORDERS, orders);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_ORDERS);
    }
  },
  [ORDER_EDIT_FIELD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put(ORDER_RESOURCE_FIELD, payload)
        .then(() => {
          let orders = context.state.orders;
          let newPayload = { ...payload };

          const index = orders.findIndex(o => o.id === newPayload.id);
          if (index !== -1) {
            orders[index][newPayload.name] = newPayload.value;
            context.commit(SET_ORDERS, orders);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_ORDERS);
    }
  },
  [ORDER_EDIT_FILE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put(ORDER_RESOURCE_FILE, payload)
        .then(({ data }) => {
          let orders = context.state.orders;
          let newPayload = { ...payload };
          const index = orders.findIndex(o => o.id === newPayload.orderId);
          if (index !== -1) {
            // console.log(orders[index]["filePath"]);
            orders[index]["filePath"] = data.data.filePath;
            // console.log(orders[index]["filePath"]);
            context.commit(SET_ORDERS, orders);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_ORDERS);
    }
  },
  [CLEAR_ORDERS](context) {
    context.commit(CLEAR_ORDERS);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_ORDERS](state, orders) {
    state.orders = orders;
  },
  [PURGE_ORDERS](state) {
    state.orders = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [CLEAR_ORDERS](state) {
    state.orders = [];
  },
  [SET_IS_PRINTING](state, isPrinting) {
    state.isPrinting = isPrinting;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
