import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {CLIENT_EDIT_FIELD_OFFLINE} from "@/core/services/store/client.module";

// consts
export const NOTICE_RESOURCE = "notice";
export const NOTICE_FOR_CLIENT_RESOURCE = "notice/client";
export const NOTICE_SOFT_DELETE_RESOURCE = NOTICE_RESOURCE;
export const NOTICE_HARD_DELETE_RESOURCE = NOTICE_RESOURCE + "/" + "delete";

// action types
export const NOTICE_LIST = "noticesList";
export const NOTICE_FOR_CLIENT_LIST = "noticesForClientList";
export const NOTICE_DELETE = "noticesDelete";
export const NOTICE_SOFT_DELETE = "noticesSoftDelete";
export const NOTICE_HARD_DELETE = "noticesHardDelete";
export const NOTICE_ADD = "noticesAdd";
export const NOTICE_EDIT = "noticesEdit";
export const CLEAR_NOTICES = "clearNotices";

// mutation types
export const SET_NOTICES = "setNotices";
export const SET_ERROR = "setError";
export const PURGE_NOTICES = "purgeNotices";

const state = {
  errors: null,
  notices: null,
  latestNotices: null,
};

const getters = {
  notices(state) {
    return state.notices;
  },
  latestNotices(state) {
    return state.latestNotices
  }
};

const actions = {
  [NOTICE_LIST](context, data) {
    if ( undefined === data || undefined === data.id )
      return;

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(NOTICE_RESOURCE + "/" + data.id)
        .then(({ data }) => {
          context.commit(SET_NOTICES, data.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_NOTICES);
    }
  },
  getLatestNoticeForItems(context, data) {
    if ( undefined === data || undefined === data.ids )
      return;

    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(NOTICE_RESOURCE + "/latest/" + data.ids.join(','))
        .then(({ data }) => {
          context.commit("setLatestNotices", data.data);
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_NOTICES);
    }
  },
  [NOTICE_FOR_CLIENT_LIST](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.get(NOTICE_FOR_CLIENT_RESOURCE, id)
        .then(({ data }) => {
          context.commit(SET_NOTICES, data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_NOTICES);
    }
  },
  [NOTICE_SOFT_DELETE](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id } = payload;
      ApiService.delete(NOTICE_SOFT_DELETE_RESOURCE, id)
        .then(({ data }) => {
          let notices = context.state.notices;
          let newNotice = data.data.notice;
          let client = newNotice.client;
          const index = notices.findIndex(n => n.id === id);
          if (index !== -1) {
            notices.splice(index, 1);
            context.commit(SET_NOTICES, notices);
          }
          context.dispatch(CLIENT_EDIT_FIELD_OFFLINE, {
            name: "updatedAt",
            value: client.updatedAtObj,
            id: client.id
          });
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_NOTICES);
    }
  },
  [NOTICE_ADD](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post(NOTICE_RESOURCE, payload)
        .then(async ({ data }) => {
          let notes = context.state.notices;
          let newNotice = data.data.notice;
          let client = newNotice.client;
          notes.push(newNotice);
          context.commit(SET_NOTICES, notes);
          context.dispatch(NOTICE_LIST, {
            id: client.id
          });
          context.dispatch(CLIENT_EDIT_FIELD_OFFLINE, {
            name: "updatedAt",
            value: client.updatedAtObj,
            id: client.id
          });
        })
        .catch(({ response }) => {
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_NOTICES);
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_NOTICES](state, notices) {
    state.notices = notices;
  },
  [PURGE_NOTICES](state) {
    state.notices = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [CLEAR_NOTICES](state) {
    state.notices = null;
  },
  setLatestNotices(state, data) {
    state.latestNotices = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
