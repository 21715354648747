import ApiService from "../api.service";
import JwtService from "../jwt.service";

export const SOILCARD_RESOURCE = "labOrder";

const state = {
  labOrders: [],
  labOrder: null,
  errors: null
};

const getters = {
  labOrders: ( state ) => state.labOrders,
  labOrder: ( state ) => state.labOrder
}

const actions = {
  getLabOrder(context, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(SOILCARD_RESOURCE + "/" + data.id)
        .then(({ data }) => {
          context.commit("setLabOrder", data.data);
        })
        .catch(({ response }) => {
          context.commit("setError", response);
        });
    } else {
      context.commit("purgeLabOrders");
    }
  },
  orderLabList(context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(SOILCARD_RESOURCE)
        .then(({ data }) => {
          context.commit("setLabOrders", data.data);
        })
        .catch(({ response }) => {
          context.commit("setError", response);
        });
    } else {
      context.commit("purgeLabOrders");
    }
  },
  orderLabAdd(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.post(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("orderLabList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  orderLabEdit(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    ApiService.put(SOILCARD_RESOURCE, data)
      .then(() => ctx.dispatch("orderLabList"))
      .catch(({ response }) => {
        // console.error(response)
        ctx.commit("setError", response);
      });
  },
  orderLabDelete(ctx, data) {
    if (!JwtService.getToken()) ctx.commit("purgeVacation");
    ApiService.setHeader();

    const { id } = data;
    ApiService.delete(SOILCARD_RESOURCE, id)
      .then(() => ctx.dispatch("orderLabList"))
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  },
  orderLabPrint(context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      const { id, template } = payload;
      ApiService.download('laborder/print/' + template, id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "zlecenie_badania-" + template + ".pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(({ response }) => {
          context.commit("setError", response);
        })
    }
  }
};

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setLabOrders(state, data) {
    state.labOrders = data;
  },
  setLabOrder(state, data) {
    state.labOrder = data;
  },
  purgeLabOrders(state) {
    state.errors = null;
    state.labOrders = [];
  }
}

export default {
  state, actions, mutations, getters
};