import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";

const state = {
  error: null,
  business_data: []
};

const getters = {
  business_data: state => state.business_data
};

const actions = {
  businessDataList(ctx) {
    if (!JwtService.getToken()) ctx.commit("purgeBusinessData");

    ApiService.setHeader();
    ApiService.get("business-analysis")
      .then(({ data }) => {
        ctx.commit("setBusinessData", data.data);
      })
      .catch(({ response }) => {
        ctx.commit("setError", response);
      });
  }
};

const mutations = {
  setError(state, error) {
    state.error = error;
  },
  setBusinessData(state, data) {
    state.business_data = data;
  },
  purgeBusinessData(state) {
    state.error = null;
    state.business_data = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
