import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import _ from "lodash";

// export const baseURL =
//   process.env.NODE_ENV === "development"
//     ? baseUrlEnvDevelopment
//     : baseUrlEnvProduction;

export let baseURL = process.env.VUE_APP_BASE_URL;

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = `${baseURL}api/`;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  download(resource, slug = "") {
    let url = `${resource}`;
    if (slug !== "") {
      url = `${resource}/${slug}`;
    }
    return Vue.axios
      .get(url, {
        method: "GET",
        responseType: "blob"
      })
      .catch(error => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @param queryParams
   * @returns {*}
   */
  get(resource, slug = "", queryParams = {}) {
    let url = `${resource}`;
    if (slug !== "") {
      url = `${resource}/${slug}`;
    }
    if (!_.isEmpty(queryParams)) {
      const config = {
        params: queryParams
      };
      return Vue.axios.get(url, config).catch(error => {
        throw new Error(`[KT] ApiService ${error}`);
      });
    }
    return Vue.axios.get(url).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  delete(resource, slug) {
    return Vue.axios.delete(`${resource}/${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
